import type React from 'react'

import {
  faMapMarkerAlt,
  faGavel,
  faSync,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { differenceInHours } from 'date-fns/differenceInHours'
import Link from 'next/link'

import { getStaticPath } from '@b-stock/bstock-next'
import {
  FormattedMessage,
  CurrencyStringDollars,
  CurrencyStringCents,
} from '@b-stock/bstock-react'

import AuctionDeadline from '@components/AuctionDeadline'
import Tag from '@components/features/auction/details/Bid/Tag'
import TextTransition from '@components/TextTransition'
import { BidStatus } from '@queries/listingSearchQuery'
import type { SearchResult } from '@queries/listingSearchQuery'

import {
  AuctionImage,
  Title,
  Container,
  SalvageTag,
  SalvageIcon,
  ImageContainer,
  Price,
  PriceContainer,
  BidAmountContainer,
  BidAmount,
  WinAt,
  BidsCountAndTime,
  TimeLeftContainer,
  BidsCount,
  VerticalDivider,
  Msrp,
  MsrpAmount,
  Chips,
  ImageOverlay,
  ImageFooter,
  SellerContainer,
  SoldByContainer,
  SoldByLabel,
  SellerName,
  LocationInfo,
  LocationIcon,
  CardDescription,
} from './shared'
import PopcornInfo from '../PopcornInfo'

export type SearchResultCardProps = {
  result: SearchResult
  className?: string
  showSoldBy?: boolean
}

const SearchResultCard: React.FC<SearchResultCardProps> = ({
  result,
  className,
  showSoldBy,
}) => {
  const { bidStatus = '', winningBidAmount = 0, categories, bid } = result
  const hasBids = bidStatus !== BidStatus.NO_BID
  const won = bidStatus === BidStatus.WON
  const losing = bidStatus === BidStatus.LOSING
  const lost = bidStatus === BidStatus.LOST
  const endTimeLeft = differenceInHours(new Date(), new Date(result.endTime))
  const hot = endTimeLeft > 0 && endTimeLeft < 1
  const salvage = !!categories?.length && categories[0] === 'salvage'
  const detailsUrl = `/listings/details/${result.listingId}`
  const maxBidAmount = bid?.bidAmount || 0

  return (
    <Link href={detailsUrl}>
      <Container className={className} role="search-result-card">
        <ImageContainer>
          <Chips>
            {bidStatus && hasBids && (
              <Tag
                bidStatus={result.bidStatus}
                noMargin
                order={
                  // We intentionally don't check for or show if the order
                  // has been created or not in this context.
                  null
                }
              />
            )}

            {salvage && (
              <SalvageTag>
                <SalvageIcon icon={faSync} />
                <FormattedMessage
                  id="SearchResultCard.salvage"
                  defaultMessage="SearchResultCard.salvage"
                />
              </SalvageTag>
            )}
          </Chips>

          <AuctionImage
            src={
              result.primaryImageUrls?.medium ??
              getStaticPath('images/placeholder-image.png')
            }
          />

          <ImageOverlay>
            <ImageFooter>
              <SoldByContainer>
                <SellerContainer>
                  {showSoldBy && (
                    <>
                      <SoldByLabel>
                        <FormattedMessage id="SearchResultCard.soldBy" />
                      </SoldByLabel>
                      <SellerName>{result.siteName}</SellerName>
                    </>
                  )}
                </SellerContainer>
              </SoldByContainer>
            </ImageFooter>
          </ImageOverlay>
        </ImageContainer>

        <CardDescription>
          <div>
            <Title>{result.title}</Title>

            <LocationInfo>
              <LocationIcon icon={faMapMarkerAlt} />

              <span>
                {result.sellerCity}, {result.sellerRegion}
              </span>
            </LocationInfo>
          </div>

          <div>
            {hasBids ? (
              <BidAmountContainer>
                <>
                  {!losing && !lost ? (
                    <WinAt>
                      <FormattedMessage id="SearchResultCard.winningAt" />
                    </WinAt>
                  ) : null}

                  <BidAmount $losing={losing || lost}>
                    <FormattedMessage
                      id={`SearchResultCard.${
                        losing || lost ? 'yourLastBidAmount' : 'maxBidAmount'
                      }`}
                      values={{ amount: maxBidAmount }}
                    />
                  </BidAmount>
                </>
              </BidAmountContainer>
            ) : null}
            {!hasBids && result.retailPrice ? (
              <Msrp>
                <FormattedMessage id="SearchResultCard.msrp" />
                <MsrpAmount>
                  <CurrencyStringCents
                    value={result.retailPrice}
                    currency="USD"
                  />
                </MsrpAmount>
              </Msrp>
            ) : null}

            <PriceContainer>
              <Price $won={won} $lost={lost}>
                <TextTransition transitionKey={winningBidAmount}>
                  <CurrencyStringDollars
                    value={winningBidAmount}
                    currency="USD"
                    omitDecimal
                  />
                </TextTransition>
              </Price>
            </PriceContainer>

            <BidsCountAndTime>
              <TimeLeftContainer $hot={hot}>
                <AuctionDeadline
                  formattedBidDeadline={null}
                  deadline={result.endTime}
                  distance
                />
              </TimeLeftContainer>

              {hot && <PopcornInfo amount={2} />}

              <VerticalDivider />

              <BidsCount>
                <FontAwesomeIcon icon={faGavel} />
                {result.numberOfBids || 0}
              </BidsCount>
            </BidsCountAndTime>
          </div>
        </CardDescription>
      </Container>
    </Link>
  )
}

export default SearchResultCard
