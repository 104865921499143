import { styled } from 'styled-components'

import { BidStatus } from '@b-stock/bstock-next'
import { Tag, TagType, FormattedMessage } from '@b-stock/bstock-react'
import { breakpoints, typography } from '@b-stock/bstock-react/theme'
import type { ContractDto } from '@b-stock/contract-api-client'

import type { OrderForWonAuction } from '@queries/orderForWonAuctionQuery'

export enum TagStatus {
  Won = 'WON',
  Lost = 'LOST',
  Winning = 'WINNING',
  Losing = 'LOSING',
  Placing = 'PLACING',
}

/** temporary enum for BuyNow order status */
export enum BuyNowStatus {
  BOUGHT = 'BOUGHT',
  SOLD = 'SOLD',
}

export const getTagStatus = (bidState: BidStatus, isLoading: boolean) => {
  if (bidState !== BidStatus.NO_BID && isLoading) {
    return TagStatus.Placing
  }

  if (bidState === BidStatus.WINNING) {
    return TagStatus.Winning
  }

  if (bidState === BidStatus.LOSING) {
    return TagStatus.Losing
  }

  if (bidState === BidStatus.WON) {
    return TagStatus.Won
  }

  if (bidState === BidStatus.LOST) {
    return TagStatus.Lost
  }

  return null
}

const StyledTag = styled(Tag)<{ $noMargin?: boolean }>`
  ${typography.subtitle3}
  margin-left: ${(o) => !o.$noMargin && '2rem'};
  margin-bottom: ${(o) => !o.$noMargin && '0.5rem'};

  @media ${breakpoints.max.medium} {
    ${(o) =>
      o.$noMargin
        ? ``
        : `
      margin-left: 1rem;
      `}
  }
`

const isOrderCanceled = (order?: OrderForWonAuction) => {
  return order?.closure?.reason && order.closure.reason !== 'COMPLETED'
}

const getTagType = (
  bidStatus: BidStatus | BuyNowStatus,
  order?: OrderForWonAuction
): TagType => {
  if (
    bidStatus === BuyNowStatus.SOLD ||
    bidStatus === BidStatus.LOSING ||
    bidStatus === BidStatus.LOST ||
    isOrderCanceled(order)
  ) {
    return TagType.error
  }
  return TagType.success
}

const getMessageKey = (
  bidStatus: BidStatus | BuyNowStatus,
  isAuctionCanceled: boolean,
  order?: OrderForWonAuction,
  contract?: ContractDto | null
) => {
  if (bidStatus === BidStatus.WON || bidStatus === BuyNowStatus.BOUGHT) {
    if (isAuctionCanceled) {
      return 'WON_AND_CANCELED'
    }
    /** temporary comparison for deriving BuyNow order status */
    if (bidStatus === BuyNowStatus.BOUGHT) {
      return isOrderCanceled(order)
        ? 'BOUGHT_ORDER_CANCELED'
        : 'BOUGHT_AND_CREATED'
    }

    if (order) {
      return isOrderCanceled(order) ? 'WON_ORDER_CANCELED' : 'WON_AND_CREATED'
    }

    // NOTE: there may be more logic around contract winning tag
    if (contract) {
      return 'WON_CONTRACT_CREATED'
    }
  }
  return bidStatus
}

const StatusTag = ({
  bidStatus,
  className,
  noMargin,
  order,
  contract,
}: {
  bidStatus: BidStatus | BuyNowStatus
  className?: string
  noMargin?: boolean
  order?: OrderForWonAuction
  contract?: ContractDto | null
}) => {
  if (bidStatus === BidStatus.NO_BID) return null
  return (
    <StyledTag
      type={getTagType(bidStatus, order)}
      className={className}
      $noMargin={noMargin}
    >
      <FormattedMessage
        id={`Auction.details.tag.${getMessageKey(bidStatus, false, order, contract)}`}
      />
    </StyledTag>
  )
}

export default StatusTag
